<template>
    <div class="ad-cont">
        <div class="ad" id='div-gpt-ad-1732104416799-0'></div>
    </div>
</template>

<script>
export default {
    mounted() {
        /* eslint-disable */
        googletag = window.googletag;

        // googletag.cmd.push(function () {
        //     googletag.defineSlot('/23175089080/kennethgames.com_Anchor_300x100_2', [300, 100], 'div-gpt-ad-1732104416799-0').addService(googletag.pubads());
        //     googletag.pubads().enableSingleRequest();
        //     googletag.enableServices();
        // });
        googletag.cmd.push(function () { googletag.display('div-gpt-ad-1732104416799-0'); });
    }
}
</script>

<style lang="less" scoped>
.ad-cont{
    width: 100%;
    background: #f1f1f1;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index:99999;
}
.ad{
    min-width: 300px;
    width: 300px;
    margin: 0 auto;
}
</style>